import { H, Section } from "@jfrk/react-heading-levels";
import { getMainArchivePageTitleFromPageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/contentType";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

import SEO from "../../../components/SEO";

import * as styles from "./StaticMainArchive.module.css";

export default function StaticMainArchive() {
  const pageContext = usePageContext();
  let type = pageContext?.contentType?.enum;
  let title = getMainArchivePageTitleFromPageContext(pageContext);
  if (type === "EVENT") {
    title = "Evenemang";
  }
  return (
    <>
      <SEO title={title} />
      <div className="c-article o-margin-top-large">
        <div className="o-grid">
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit">
              <H className="c-article__title">{title}</H>
              <Section>
                <div className={styles.spinner}>
                  <BounceLoader color={"#de0b0b"} loading={true} size={80} />
                </div>
              </Section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
